import { IProject } from "@/lib/model";
import { loadProjects } from '@/lib/utils';
import { OnShare } from "@/routes/Share";
import {
  Box,
  Center,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import { Download, ExternalLink, FileVideo, Images, Megaphone, Share } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Preview } from "./Preview";


export default function Builds() {
  const [projects, setProjects] = useState<IProject[]>([])
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const leastDestructiveRef = React.useRef(null)


  useEffect(() => {
    loadProjects()
      .then(data => {
        setProjects(data as IProject[])
      })
  }, [])

  const showPreview = (project: IProject) => {
    setSelectedProject(project)
    onOpen()
  }

  return (
    <Center
      width="100%"
      mb={20}
    >
      <VStack
        width="100%"
      >
        <Heading
          as={"h1"}
          size={"3xl"}
          color={"#004AAD"}
          mb={4}
        >
          Builds
        </Heading>

        {projects.map((project, index) => (
          <SimpleGrid
            key={index}
            columns={2}
            p={4}
            boxShadow="sm"
            borderRadius="lg"
            _hover={{ boxShadow: "md" }}
            transition="box-shadow 0.2s"
            cursor="pointer"
            width="100%"
            className="hover:scale-105 transition-all duration-300 ease-in-out"
          >
            <Flex
              alignItems='center'
              justifyContent={"flex-end"}
              textAlign="right"
              pr={4}
              onClick={() => showPreview(project)}
            >
              <Text fontWeight="medium" fontSize="lg">{project.date}</Text>
            </Flex>

            <VStack
              textAlign="left"
              pl={4}
              py={2}
              borderLeftWidth="2px"
              borderColor="gray.300"
            >
              <div className={"min-w-full h-auto"} onClick={() => showPreview(project)}>
                <Top title={project.title} subtitle={project.subtitle} logo={project.logo} />
              </div>

              <div className={"min-w-full h-auto"}>
                <Links project={project} showPreview={showPreview} />
              </div>
            </VStack>
          </SimpleGrid>
        ))}

        {selectedProject && (
          <Preview
            selectedProject={selectedProject}
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={leastDestructiveRef}
          />
        )}
      </VStack>
    </Center>
  )
}

function Top({ title, subtitle, logo }: { title: string, subtitle: string, logo?: string }) {
  return (
    <Box>
      <Heading
        as={"h1"}
        fontFamily="AlegreyaSans-Black"
        fontSize="3xl"
        fontWeight="semibold"
        color={"#004AAD"}
        className="hover:scale-105 transition-all hover:translate-x-3 duration-300 ease-in-out"
      >
        <span className={"text-2xl inline-flex items-center"}>{logo && <img src={logo} alt={title} className={"h-8 w-8 mr-2"} />}{title}</span>
      </Heading>

      <Text
        fontFamily="AlegreyaSans-Black"
        fontSize="md"
        color="gray.600"
        mt={2}
      >
        {subtitle}
      </Text>
    </Box>
  )
}

interface LinksProps {
  project: IProject
  showPreview: (project: IProject) => void
}

function Links({ project, showPreview }: LinksProps) {
  const toast = useToast()

  return (
    <Box>
      <div className={"flex flex-row gap-2"}>
        {project.pictures && project.pictures?.length > 0 && (
          <Tooltip label={"Browser gallery"}>
            <div onClick={() => showPreview(project)}>
              <Images />
            </div>
          </Tooltip>
        )}

        {project.video && (
          <Tooltip label={"Watch video"}>
            <div onClick={() => showPreview(project)}>
              <FileVideo />
            </div>
          </Tooltip>
        )}

        {project.post && (
          <Tooltip label={"Read it"}>
            <Link
              href={`https://x.com/zeffmuks/status/${project.post}`}
              isExternal
              _hover={{ textDecoration: 'none' }} // Remove underline on hover
            >
              <Megaphone />
            </Link>
          </Tooltip>
        )}

        {project.downloadItLink && (
          <Tooltip label={"Download it"}>
            <Link
              href={project.downloadItLink}
              isExternal
              _hover={{ textDecoration: 'none' }} // Remove underline on hover
            >
              <Download />
            </Link>
          </Tooltip>
        )}

        {project.tryItOutLink && (
          <Tooltip label={"Try it"}>
            <Link
              href={project.tryItOutLink}
              isExternal
              _hover={{ textDecoration: 'none' }} // Remove underline on hover
            >
              <ExternalLink />
            </Link>
          </Tooltip>
        )}

        <Tooltip label={"Share it"}>
          <Share onClick={() => OnShare({
            title: project.title,
            text: project.description,
            url: `${window.location.origin}/project/${project.id}`,
            toast: toast
          })} />
        </Tooltip>
      </div>
    </Box>
  );
}

