import {Box, Text, Center, VStack} from "@chakra-ui/react";

export default function ForgePrivacyPolicy() {
    return (
        <Box p={5}>
            <Center>
                <VStack>
                    <Text fontSize="xl" fontWeight="bold">
                        Forge, Privacy Policy
                    </Text>

                    <Text mt={3}>Effective Date: 12/28/2023</Text>
                </VStack>
            </Center>

            <Text mt={3}>
                Welcome to Forge, a Chrome Extension designed to enhance your productivity while prioritizing your privacy. Our commitment is to ensure a secure and private user experience. This Privacy Policy outlines our practices concerning the handling of your information.
            </Text>

            <Text mt={3} fontWeight="bold">
                Data Collection and Usage
            </Text>

            <Text mt={3}>No Data Collection: Forge does not collect or store any personal data. All information processed by the extension remains on your local device within Chrome's storage.</Text>
            <Text mt={3}>Local Storage: When you select text on a webpage and utilize our "Generate Flashcards" feature, the data is temporarily used to facilitate the service but is not stored or retained by the extension. It is directly sent to OpenAI's servers using your API key.</Text>
            <Text mt={3}>API Key: You provide your own OpenAI API key to use the "Generate Flashcards" feature. This means you have control over your data, and it's up to you to ensure you do not use the service for sensitive or private data.</Text>

            <Text mt={3} fontWeight="bold">
                OpenAI Privacy Practices
            </Text>

            <Text mt={3}>Data Handling by OpenAI: When using the "Generate Flashcards" feature, your selected text is sent to OpenAI. The handling and processing of this data are subject to OpenAI's Privacy Policy. We encourage you to review their policy to understand their data practices.</Text>

            <Text mt={3} fontWeight="bold">
                Your Responsibilities
            </Text>

            <Text mt={3}>Sensitive Data: You are responsible for avoiding the use of the "Generate Flashcards" feature with sensitive or private data. Since you provide your own API key, you bear the responsibility for any data sent to OpenAI.</Text>


            <Text mt={3} fontWeight="bold">Changes to This Privacy Policy</Text>
            <Text mt={3}>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </Text>

            <Text mt={3} fontWeight="bold">Contact Us</Text>
            <Text mt={3}>
                If you have any questions about this Privacy Policy, please contact us.
            </Text>

            <Text mt={3}>
                By using Forge, you agree to the collection and use of information in accordance with this policy. Your privacy is our priority, and we are committed to protecting it.
            </Text>
        </Box>
    )
}