import { Box, Center, Heading, Image, Link, VStack } from "@chakra-ui/react";
import { Helmet } from 'react-helmet-async';
import Builds from "./Builds";


export default function Home() {
  return (
    <Box>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"Zeff Muks"} />
        <meta property="og:description" content={"Antifragile Entropy Assassin 🥷"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={"https://www.zeffmuks.com/images/ZeffMuks-1920.png"} />
        <meta property="og:site_name" content="Zeff Muks" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zeffmuks" />
        <meta name="twitter:title" content="Zeff Muks" />
        <meta name="twitter:description" content="Antifragile Entropy Assassin 🥷" />
        <meta name="twitter:image" content={"https://www.zeffmuks.com/images/ZeffMuks-1920.png"} />
      </Helmet>

      <Center
        width="100%"
        height="100vh"
      >
        <VStack
          textAlign="center"
        >
          <Image src={"/images/ZeffMuks-6912.png"} />


          <Heading
            as="h1"
            size={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
            mt={5}
          >
            Antifragile Entropy Assassin 🥷🏻
          </Heading>

          <Heading
            as="h2"
            size="2xl"
            mt={5}
          >
            <Link href={"https://x.com/zeffmuks"}>
              𝕏
            </Link>
          </Heading>
        </VStack>
      </Center>

      <Builds />
    </Box>
  )
}