import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "./Error";
import ForgePrivacyPolicy from "./policies/ForgePrivacy";
import Home from "./routes/Home";
import Project from "./routes/Project";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Error />
    },
    {
        path: "/project/:id",
        element: <Project />,
        errorElement: <Error />
    },
    {
        path: "/privacy/forge",
        element: <ForgePrivacyPolicy />,
        errorElement: <Error />
    },
    {
        path: "/projects.yml",
        element: null,
        loader: ({ request }) => fetch(request.url).then(res => res.text())
    }
])

function MyApp() {
    return (
        <HelmetProvider>
            <RouterProvider router={router} />
        </HelmetProvider>
    );
}

export default MyApp;