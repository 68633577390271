import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious, } from "@/components/ui/carousel";
import { IProject, IProjectPreviewProps } from "@/lib/model";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  Link,
  ListItem,
  Table,
  Td,
  Text,
  Th,
  UnorderedList,
  useToast,
  VStack,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { Tweet } from 'react-tweet';
import { ShareButton } from "./Share";


const MarkdownComponents = {
  h1: (props: any) => <Heading as="h1" size="xl" my={4} {...props} />,
  h2: (props: any) => <Heading as="h2" size="lg" my={3} {...props} />,
  h3: (props: any) => <Heading as="h3" size="md" my={2} {...props} />,
  p: (props: any) => <Text mb={2} {...props} />,
  ul: (props: any) => <UnorderedList mb={2} {...props} />,
  li: (props: any) => <ListItem {...props} />,
  a: (props: any) => <Link color={"#004AAD"} {...props} />,
  table: (props: any) => <Table {...props} />,
  th: (props: any) => <Th {...props} />,
  td: (props: any) => <Td {...props} />,
};

export function Preview({ selectedProject, isOpen, onClose, leastDestructiveRef }: IProjectPreviewProps) {
  const toast = useToast()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          maxWidth="80%"
          maxHeight="80%"
          width="70%"
          height="auto"
          margin="auto"
          className="flex justify-center items-center"
        >
          <AlertDialogHeader
            textAlign={"center"}
          >
            {/*Title*/}
            <Heading
              as={"h1"}
              size={"2xl"}
              color={"#004AAD"}
              mt={4}
            >
              {selectedProject.title}
            </Heading>
          </AlertDialogHeader>

          {selectedProject.logo && (
            <div className="flex justify-center m-4">
              <img src={selectedProject.logo} alt={selectedProject.title} width={128} height={128} />
            </div>
          )}

          <AlertDialogBody
            textAlign={"center"}
            overflowY="auto"
            className="min-w-full"
          >
            {/*Description*/}
            <Text
              mb={4}
            >
              {selectedProject.description}
            </Text>

            {/* Features*/}
            <Box
              minW={"100%"}
              maxW={"100%"}
            >
              <Heading
                as={"h1"}
                size={"xl"}
                my={4}
              >
                Features
              </Heading>

              <VStack spacing={4}>
                {selectedProject.features.map((feature, index) => (
                  <Box key={index}>
                    <Text fontWeight="bold">{feature.name}</Text>
                    <Text fontSize="sm">{feature.description}</Text>
                  </Box>
                ))}
              </VStack>
            </Box>

            {/*Instructions*/}
            {selectedProject.instructions && (
              <Box
                minW={"100%"}
                maxW={"100%"}
              >
                <Heading
                  as={"h1"}
                  size={"xl"}
                  my={4}
                >
                  Instructions
                </Heading>


                <Text fontSize="sm">
                  {selectedProject.instructions}
                </Text>
              </Box>
            )}

            {/*Instructions Markdown*/}
            {selectedProject.instructions_md && (
              <Box>
                <Heading
                  as={"h1"}
                  size={"xl"}
                  my={4}
                >
                  Instructions
                </Heading>

                <Box
                  textAlign="left"
                  px={4}
                  py={2}
                  bg="gray.50"
                  borderRadius="md"
                  border="1px"
                  borderColor="gray.200"
                >
                  <ReactMarkdown components={MarkdownComponents}>
                    {selectedProject.instructions_md}
                  </ReactMarkdown>
                </Box>
              </Box>
            )}

            {/*Preview*/}
            {(selectedProject.pictures || selectedProject.video || selectedProject.post) && (
              <Box className="flex flex-col min-w-full justify-center">
                {selectedProject.pictures && (
                  <div>
                    <Heading
                      as={"h1"}
                      size={"xl"}
                      my={4}
                    >
                      Gallery
                    </Heading>

                    <div className={"flex min-w-full justify-center"}>
                      <Carousel className={"max-w-[80%]"}>
                        <CarouselContent>
                          {selectedProject.pictures.map((picture, index) => (
                            <CarouselItem key={index}>
                              <img src={picture} alt={`echo-${index}`}
                                className={"w-auto h-auto"} />
                            </CarouselItem>
                          ))}
                        </CarouselContent>
                        <CarouselPrevious />
                        <CarouselNext />
                      </Carousel>
                    </div>
                  </div>
                )}

                {selectedProject.video && (
                  <>
                    <Heading
                      as={"h1"}
                      size={"xl"}
                      my={4}
                    >
                      Preview
                    </Heading>

                    <div className="flex justify-center items-center">
                      <video width="750" height="500" controls>
                        <source src={selectedProject.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </>
                )}

                {selectedProject.post && (
                  <div>
                    <Heading
                      as={"h1"}
                      size={"xl"}
                      my={4}
                    >
                      Preview
                    </Heading>

                    <div className="flex justify-center items-center">
                      <Tweet id={selectedProject.post} />
                    </div>
                  </div>
                )}
              </Box>
            )}
          </AlertDialogBody>

          <AlertDialogFooter
            justifyContent="center" // Center the footer content
            py={4} // Padding top and bottom
          >
            {(selectedProject.downloadItLink || selectedProject.tryItOutLink) && (
              <Link
                href={getLink({ project: selectedProject })}
                isExternal
                _hover={{ textDecoration: 'none' }} // Remove underline on hover
              >
                <Button
                  colorScheme="gray"
                  p={2}
                  mb={4}
                >
                  Try It Out
                </Button>
              </Link>
            )}

            <ShareButton
              title={selectedProject.title}
              text={selectedProject.description}
              url={`${window.location.origin}/project/${selectedProject.id}`}
              toast={toast}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog >
  );
}

function getLink({ project }: { project: IProject }) {
  return project.tryItOutLink ? project.tryItOutLink : project.downloadItLink
}